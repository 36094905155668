import { RefObject } from 'react';

const MAGRITTE_PADDING = 16;

export const getMaxHeight = (ref: RefObject<HTMLElement>, defaultValue = 460): number => {
    if (ref.current && typeof window !== 'undefined') {
        return window.innerHeight - ref.current.getBoundingClientRect().bottom - 2 * MAGRITTE_PADDING;
    }

    return defaultValue;
};
