import { Text, Card } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { MenuItem } from 'bloko/blocks/drop';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { NegotiationLink } from 'src/models/employerNegotiations/negotiationLinks';
import { StateId } from 'src/models/employerNegotiations/stateId.types';
import { EmployerStateExtName } from 'src/models/negotiationTopic.types';

const TrlKeys: Partial<Record<EmployerStateExtName, string>> = {
    [EmployerStateExtName.DiscardByEmployer]: 'negotiations.states.discardByEmployer.softForm',
    [EmployerStateExtName.DiscardByApplicant]: 'negotiations.states.discardByApplicant',
    [EmployerStateExtName.DiscardNoInteraction]: 'negotiations.states.discardNoInteraction',
    [EmployerStateExtName.DiscardToOtherVacancy]: 'negotiations.states.discardToOtherVacancy',
    [EmployerStateExtName.DiscardVacancyClosed]: 'negotiations.states.discardVacancyClosed',
};

export interface SendAnalyticsHandler {
    (state: StateId): void;
}

interface ChangeTopicMenuItemProps {
    name: StateId;
    url: NegotiationLink['url'];
    sendAnalytics?: SendAnalyticsHandler;
}

const ChangeTopicMenuItem: TranslatedComponent<ChangeTopicMenuItemProps> = ({ trls, name, url, sendAnalytics }) => {
    const isMagritte = useMagritte();

    const trlKey = TrlKeys[name];
    const dataQa = `change_topic_menu_item__${name}`;

    if (!trlKey) {
        return null;
    }

    if (typeof url === 'function') {
        if (isMagritte) {
            return (
                <Card
                    onClick={() => {
                        sendAnalytics?.(name);
                        url();
                    }}
                    borderRadius={16}
                    padding={12}
                    paddingLeft={16}
                    hoverStyle="neutral"
                    actionCard
                    stretched
                    data-qa={dataQa}
                >
                    <Text style="primary" typography="label-2-regular">
                        {trls[trlKey]}
                    </Text>
                </Card>
            );
        }
        return (
            <MenuItem
                Element="button"
                onClick={() => {
                    sendAnalytics?.(name);
                    url();
                }}
                data-qa={dataQa}
            >
                {trls[trlKey]}
            </MenuItem>
        );
    }

    if (isMagritte) {
        return (
            <Card
                Element={Link}
                to={url}
                onClick={() => sendAnalytics?.(name)}
                borderRadius={16}
                padding={12}
                paddingLeft={16}
                hoverStyle="neutral"
                actionCard
                stretched
                data-qa={dataQa}
            >
                <Text style="primary" typography="label-2-regular">
                    {trls[trlKey]}
                </Text>
            </Card>
        );
    }
    return (
        <MenuItem Element={Link} to={url} onClick={() => sendAnalytics?.(name)} data-qa={dataQa}>
            {trls[trlKey]}
        </MenuItem>
    );
};

export default translation(ChangeTopicMenuItem);
