import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    successMessage: 'employer.applicantsBlacklist.success',
    errorMessage: 'employer.applicantsBlacklist.error.BLACKLIST_EXCEEDED',
    complaintReasonMessage: 'employer.vacancyResponses.complaintReason.postError',
};

const CompletionBlacklistSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.successMessage]}</>;

export const completionBlacklistSuccess = {
    Element: translation(CompletionBlacklistSuccess),
    kind: 'ok',
    autoClose: true,
};

const CompletionBlacklistError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.errorMessage]}</>;

export const completionBlacklistError = {
    Element: translation(CompletionBlacklistError),
    kind: 'error',
    autoClose: true,
};

const CompletionDefaultError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.complaintReasonMessage]}</>;

export const completionDefaultError = {
    Element: translation(CompletionDefaultError),
    kind: 'error',
    autoClose: true,
};
