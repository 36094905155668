import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    description: 'employer.vacancyResponses.pfpStub.tipDescription',
};

const Tip: TranslatedComponent = ({ trls }) => {
    return <>{trls[TrlKeys.description]}</>;
};

export default translation(Tip);
