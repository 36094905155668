import React, { FC } from 'react';

import { Checkbox, Radio, Text } from '@hh.ru/magritte-ui';
import BlokoCheckbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import BlokoRadio from 'bloko/blocks/radio';

import styles from './styles.less';

const Folder: FC<{
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selected: boolean;
    name: string;
    id: string;
    dataQAPrefix: string;
    CheckElement: typeof BlokoRadio | typeof BlokoCheckbox | typeof Radio | typeof Checkbox;
    isMagritte?: boolean;
}> = ({ id, name, selected, dataQAPrefix, onChange, CheckElement, isMagritte }) => {
    let CheckComponent = CheckElement;

    if (!CheckComponent) {
        CheckComponent = isMagritte ? Checkbox : BlokoCheckbox;
    }

    return (
        <li data-qa={`${dataQAPrefix} ${dataQAPrefix}_${id}`}>
            {isMagritte ? (
                <label className={styles.label}>
                    <CheckComponent
                        {...{
                            [CheckComponent === Radio ? 'dataQaRadio' : 'dataQaCheckbox']: `${dataQAPrefix}-checkbox`,
                        }}
                        name="favoriteFolder"
                        value={id}
                        defaultChecked={selected}
                        onChange={onChange}
                    />
                    <Text Element="span" typography="label-3-regular" data-qa={`${dataQAPrefix}-title`}>
                        {name}
                    </Text>
                </label>
            ) : (
                <FormItem baseline>
                    <CheckComponent
                        labelProps={{ 'data-qa': `${dataQAPrefix}-title` }}
                        data-qa={`${dataQAPrefix}-checkbox`}
                        name="favoriteFolder"
                        value={id}
                        defaultChecked={selected}
                        onChange={onChange}
                    >
                        {name}
                    </CheckComponent>
                </FormItem>
            )}
        </li>
    );
};

export default Folder;
