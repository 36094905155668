import { FC, ReactNode, PropsWithChildren } from 'react';

import { HSpacingContainer } from '@hh.ru/magritte-ui';

interface FormWrapperProps {
    renderVisibilityField: () => ReactNode;
    renderBodyField: (props?: { rows?: number; 'data-qa'?: string; resize?: 'vertical' }) => ReactNode;
    renderSubmit: (props?: { 'data-qa'?: string }) => ReactNode;
    renderCancel: () => ReactNode;
}

const FormWrapper: FC<FormWrapperProps & PropsWithChildren> = ({
    renderVisibilityField,
    renderBodyField,
    renderSubmit,
    renderCancel,
}) => {
    return (
        <div className="resume-sidebar-item">
            <div className="resume-comment-form">
                <div className="resume-comment-form__row">{renderBodyField({ rows: 8, resize: 'vertical' })}</div>
                {renderVisibilityField()}
                <div className="resume-comment-form__row">
                    <HSpacingContainer default={4}>
                        {renderSubmit()}
                        {renderCancel()}
                    </HSpacingContainer>
                </div>
            </div>
        </div>
    );
};

export default FormWrapper;
