import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    shareResume: 'employer.resume.share.button',
    shareVacancy: 'employer.vacancy.share.button',
    addHiringManager: 'abstract.add',
};

interface SubmitButtonProps {
    kind: 'shareResume' | 'shareVacancy' | 'addHiringManager';
    formId: string;
}

const SubmitButton: TranslatedComponent<SubmitButtonProps> = ({ trls, kind, formId }) => {
    const { isMobile } = useBreakpoint();
    return (
        <Button mode="primary" type="submit" style="accent" stretched={isMobile} form={formId}>
            {trls[TrlKeys[kind]]}
        </Button>
    );
};

export default translation(SubmitButton);
