import { Field } from 'react-final-form';

import { Checkbox, Cell, CellText } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const ACCESS_TYPES = {
    COWORKERS: 'COWORKERS',
    OWNER: 'OWNER',
};

const TrlKeys = {
    owner: 'resume.comments.accessTypes.owner',
    coworkers: 'resume.comments.accessTypes.company',
};

interface CommentVisibilityFieldProps {
    userFeatures: string[];
    accessType: string | null;
}

const CommentVisibilityField: TranslatedComponent<CommentVisibilityFieldProps> = ({
    userFeatures,
    trls,
    accessType,
}) => {
    if (!userFeatures.includes('canMakeResumeCommentsVisibleToCoworkers')) {
        return (
            <Field
                initialValue={ACCESS_TYPES.OWNER}
                name="accessType"
                type="hidden"
                render={({ input }) => <input {...input} />}
            />
        );
    }

    return (
        <div className="resume-comment-form__row">
            <Field initialValue={accessType || ACCESS_TYPES.COWORKERS} name="accessType">
                {({ input }) => (
                    <>
                        <Cell
                            left={
                                <Checkbox
                                    data-qa="comment_access_type_input"
                                    defaultChecked={input.value === ACCESS_TYPES.COWORKERS}
                                    onChange={(e) =>
                                        input.onChange(e.target.checked ? ACCESS_TYPES.COWORKERS : ACCESS_TYPES.OWNER)
                                    }
                                />
                            }
                        >
                            <CellText style="primary" type="description">
                                {trls[TrlKeys.coworkers]}
                            </CellText>
                        </Cell>
                    </>
                )}
            </Field>
        </div>
    );
};

export default translation(CommentVisibilityField);
