import { Field } from 'react-final-form';

import { Cell, CellText, Checkbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { FieldName, FormValues } from 'src/components/ResumeComplaint/ResumeComplaintForm/types';

const TrlKeys = {
    addToBlackList: 'employer.vacancyResponses.complaintReason.ADD_TO_BLACKLIST',
};

const AddToBlackListField: TranslatedComponent = ({ trls }) => {
    return (
        <Field<FormValues[FieldName.AddToBlackList]>
            name={FieldName.AddToBlackList}
            type="checkbox"
            render={({ input }) => (
                <Cell
                    left={
                        <Checkbox
                            data-qa="resume-complaint-form__to-blacklist"
                            checked={input.checked}
                            onChange={input.onChange}
                        />
                    }
                    vertPadding
                >
                    <CellText>{trls[TrlKeys.addToBlackList]}</CellText>
                </Cell>
            )}
        />
    );
};

export default translation(AddToBlackListField);
