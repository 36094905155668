import { VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { VacancyPermission as VacacnyPermissionEnum } from 'src/models/vacancy/permissions.types';

import VacancyPermission from 'src/components/HiringManager/VacancyPermissions/VacancyPermission';

const TrlKeys = {
    [VacacnyPermissionEnum.ViewContacts]: 'employer.vacancy.share.settings.contactWithApplicants',
    [VacacnyPermissionEnum.ViewDesirableCompensation]: 'employer.vacancy.share.settings.viewSalaryExpectations',
    viewContactsDescription: 'employer.vacancy.share.settings.contactWithApplicantsDescription',
};

interface VacancyPermissionsProps {
    disabled?: boolean;
}

const VacancyPermissions: TranslatedComponent<VacancyPermissionsProps> = ({ trls, disabled }) => (
    <>
        <VacancyPermission
            name={VacacnyPermissionEnum.ViewContacts}
            trl={trls[TrlKeys[VacacnyPermissionEnum.ViewContacts]]}
            descriptionTrl={trls[TrlKeys.viewContactsDescription]}
            disabled={disabled}
        />
        <VSpacing default={16} />
        <VacancyPermission
            name={VacacnyPermissionEnum.ViewDesirableCompensation}
            trl={trls[TrlKeys[VacacnyPermissionEnum.ViewDesirableCompensation]]}
            disabled={disabled}
        />
    </>
);

export default translation(VacancyPermissions);
