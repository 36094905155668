import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { NO_CONTENT, OK } from 'http-status-codes';

import resumeCardComplainButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/responses/resume_card/resume_card_complain_button_click';

import Debug from 'HHC/Debug';
import { useNotification } from 'src/components/Notifications/Provider';
import { ResumeCardActionsContext } from 'src/components/ResumeCard/Actions/Context';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

import { FormValues } from 'src/components/ResumeComplaint/ResumeComplaintForm/types';
import fetchComplaintReasonTypes from 'src/components/ResumeComplaint/fetchComplaintReasonTypes';
import negotiationComplaint from 'src/components/ResumeComplaint/negotiationComplaint';

interface UseResumeComplaintHook {
    (options: { resumeHash?: string; isResponse?: boolean; initialVisibleState?: boolean; onOpen?: () => void }): {
        isOpen: boolean;
        isHidden: boolean;
        onOpen: () => Promise<void>;
        onClose: () => void;
        onSubmit: (values: FormValues) => Promise<void>;
    };
}

const useResumeComplaint: UseResumeComplaintHook = ({
    resumeHash,
    isResponse = false,
    initialVisibleState = false,
    onOpen,
}) => {
    const isEmployerSubstatesExp = useSelector((state) => state.isEmployerSubstatesExp);
    const dispatch = useDispatch();
    const [isOpen, open, close] = useOnOffState(initialVisibleState);
    const { addNotification } = useNotification();

    const resumesComplaint = useSelector((state) => (resumeHash ? state.resumesComplaint[resumeHash] : undefined));
    const complaintReasonTypes = useSelector((state) => state.complaintReasonTypes);

    const isHidden = !resumeHash || !resumesComplaint;

    const {
        vacancyId,
        resume: { topicId },
        lastEmployerStateExtName,
    } = useContext(ResumeCardActionsContext);

    const handleOpen = useCallback(async () => {
        if (topicId && lastEmployerStateExtName) {
            resumeCardComplainButtonClick({ topicId, vacancyId, folderName: lastEmployerStateExtName });
        }

        if (onOpen) {
            onOpen();
        }

        if (complaintReasonTypes.length === 0) {
            try {
                await dispatch(fetchComplaintReasonTypes(addNotification));
            } catch (err) {
                Debug.log('out error', err);
            }
        }

        open();
    }, [
        addNotification,
        complaintReasonTypes.length,
        dispatch,
        lastEmployerStateExtName,
        onOpen,
        open,
        topicId,
        vacancyId,
    ]);

    const handleSubmit = useCallback(
        async ({ complaintReason, reasonComment = '', addToBlackList }: FormValues) => {
            if (isHidden) {
                return;
            }

            try {
                const status = await dispatch(
                    negotiationComplaint({
                        isResponse,
                        resumeHash,
                        resumesComplaint,
                        complaintReasonTypes,
                        reason: complaintReason || null,
                        blackList: addToBlackList,
                        reasonComment,
                        addNotification,
                        isEmployerSubstatesExp,
                    })
                );

                if (status === OK || status === NO_CONTENT) {
                    close();
                }
            } catch (err) {
                Debug.log('out error', err);
            }
        },
        [
            isHidden,
            dispatch,
            isResponse,
            resumeHash,
            resumesComplaint,
            complaintReasonTypes,
            addNotification,
            isEmployerSubstatesExp,
            close,
        ]
    );

    return {
        isOpen,
        isHidden,
        onClose: close,
        onOpen: handleOpen,
        onSubmit: handleSubmit,
    };
};

export default useResumeComplaint;
