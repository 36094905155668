import {
    FieldName,
    FormValues,
    ValidationError,
    ValidationErrors,
} from 'src/components/ResumeComplaint/ResumeComplaintForm/types';

const validator = (values: FormValues): ValidationErrors => {
    const errors: ValidationErrors = {};

    if (!values[FieldName.ComplaintReason]) {
        errors[FieldName.ComplaintReason] = ValidationError.Required;
    }

    return errors;
};

export default validator;
