import { useCallback } from 'react';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    button: 'employer.vacancy.share.modal.button.createHiringManager',
};

interface CreateHiringManagerButtonProps {
    vacancyId?: string;
    vacancyName?: string;
    shareVacancy: boolean;
    resumeHash?: string;
    resumeName?: string;
    onClick: () => void;
}

const CreateHiringManagerButton: TranslatedComponent<CreateHiringManagerButtonProps> = ({
    trls,
    onClick,
    resumeHash,
    resumeName,
    vacancyId,
    vacancyName,
    shareVacancy,
}) => {
    const { isMobile } = useBreakpoint();
    const ableToCreateHiringManager = useSelector((state) => state.employerManagerMeta.ableToCreateHiringManager);

    const location = useSelector((state) => state.router.location);
    const push = usePush();
    const handleClick = useCallback(() => {
        onClick?.();
        const newUrl = urlParser('/employer/managers/create');
        newUrl.params.managerType = 'HIRING_MANAGER';
        if (vacancyId && vacancyName) {
            newUrl.params.shareVacancy = shareVacancy;
            newUrl.params.vacancyId = vacancyId;
            newUrl.params.vacancyName = vacancyName;
        }
        if (resumeHash && resumeName) {
            newUrl.params.resumeHash = resumeHash;
            newUrl.params.resumeName = resumeName;
        }
        newUrl.params.redirectTo = urlParser(location.pathname + location.search).href;
        push(newUrl.href);
    }, [location, onClick, push, resumeHash, resumeName, shareVacancy, vacancyId, vacancyName]);
    const isVacancySharing = vacancyId && vacancyName;
    const isResumeSharing = resumeHash && resumeName;

    if (!ableToCreateHiringManager || (!isVacancySharing && !isResumeSharing)) {
        return null;
    }

    return (
        <Button mode="secondary" style="accent" stretched={isMobile} onClick={handleClick}>
            {trls[TrlKeys.button]}
        </Button>
    );
};

export default translation(CreateHiringManagerButton);
