import { useCallback } from 'react';
import { FormApi } from 'final-form';

import {
    HiringManagerNotificationType,
    useHiringManagerNotification,
} from 'src/components/HiringManager/hooks/useHiringManagerNotification';
import { ResumeShareFormData } from 'src/components/HiringManager/types';
import { ManagerType } from 'src/models/employerManagers.types';
import { ManagersList } from 'src/models/employerManagersPage';
import { VacancyPermission } from 'src/models/vacancy/permissions.types';
import { VacancyFull } from 'src/models/vacancyFull';
import fetcher from 'src/utils/fetcher';

const PUT_RESUME_SHARE_URL = '/shards/employer/hiring_managers/share_resume';

export type ShareResumePayload =
    | {
          resumeHash: string;
          employerManagerId: string;
          userId: string;
      }
    | {
          resumeHash: string;
          employerManagerId: string;
          userId: string;
          permissions: VacancyPermission[];
      };

declare global {
    interface FetcherPutApi {
        [PUT_RESUME_SHARE_URL]: {
            queryParams: void;
            body: {
                items: ShareResumePayload[];
                vacancyId?: string;
            };
            response: void;
        };
    }
}

export const useShareResume = (
    selectedManagers: ManagersList[],
    resumeHash: string[],
    vacancyFull: VacancyFull | null,
    onClose: () => void
): ((data: ResumeShareFormData, form: FormApi<ResumeShareFormData>) => Promise<void>) => {
    const showNotification = useHiringManagerNotification();
    return useCallback(
        async (data: ResumeShareFormData, form: FormApi<ResumeShareFormData>) => {
            try {
                const vacancyName = vacancyFull?.name;
                await fetcher.put(PUT_RESUME_SHARE_URL, {
                    items: resumeHash.reduce((acc, hash) => {
                        return acc.concat(
                            selectedManagers.map((manager) => ({
                                resumeHash: hash,
                                userId: manager.userId.toString(),
                                employerManagerId: manager.id.toString(),
                                ...(data.configureVacancyPermissions &&
                                    vacancyFull?.vacancyId && {
                                        permissions:
                                            manager.managerType === ManagerType.McpGroup ||
                                            manager.type === ManagerType.Mcp
                                                ? [
                                                      VacancyPermission.ViewContacts,
                                                      VacancyPermission.ViewDesirableCompensation,
                                                  ]
                                                : [
                                                      data[VacancyPermission.ViewDesirableCompensation] &&
                                                          VacancyPermission.ViewDesirableCompensation,
                                                      data[VacancyPermission.ViewContacts] &&
                                                          VacancyPermission.ViewContacts,
                                                  ].filter<VacancyPermission>(
                                                      (
                                                          permission: VacancyPermission | boolean
                                                      ): permission is VacancyPermission => !!permission
                                                  ),
                                    }),
                            }))
                        );
                    }, [] as ShareResumePayload[]),
                    vacancyId: vacancyFull?.vacancyId.toString(),
                });
                form.reset();
                onClose();
                showNotification?.(
                    vacancyName,
                    data.configureVacancyPermissions && vacancyFull
                        ? HiringManagerNotificationType.VacancyShared
                        : HiringManagerNotificationType.ResumeShared
                );
            } catch (e) {
                showNotification?.(vacancyFull?.name, HiringManagerNotificationType.DefaultError);
            }
        },
        [onClose, resumeHash, selectedManagers, showNotification, vacancyFull]
    );
};
