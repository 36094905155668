import { useCallback } from 'react';

import urlParser from 'bloko/common/urlParser';

import {
    HiringManagerNotificationType,
    useHiringManagerNotification,
} from 'src/components/HiringManager/hooks/useHiringManagerNotification';
import { VacancyFull } from 'src/models/vacancyFull';

export const useCopyClipboard = (
    resumeHash: string,
    vacancyFull: VacancyFull | null,
    topicId?: string
): (() => Promise<void>) => {
    const showNotification = useHiringManagerNotification();
    return useCallback(async () => {
        try {
            const url = urlParser(window.location.href);
            url.pathname = `/resume/${resumeHash}`;
            url.params = {};
            if (vacancyFull?.vacancyId) {
                url.params.vacancyId = vacancyFull.vacancyId;
            }
            if (topicId) {
                url.params.t = topicId;
            }
            await navigator.clipboard.writeText(url.href);
            showNotification?.(vacancyFull?.name, HiringManagerNotificationType.ResumeLinkCopied);
        } catch (e) {
            showNotification?.(vacancyFull?.name, HiringManagerNotificationType.ResumeLinkCopyFailed);
        }
    }, [resumeHash, vacancyFull, topicId, showNotification]);
};
