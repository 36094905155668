import { Fragment, ReactNode, useCallback, useEffect, useRef } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';

import { Button, UncontrolledTextArea } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import Form from 'src/components/Form';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import { ResumeComment } from 'src/models/employer/resume/resumeComments';
import fetcher from 'src/utils/fetcher';

import CommentVisibilityField from 'src/components/ResumeComments/CommentVisibilityField';
import renderForm from 'src/components/ResumeComments/FormWrapper';
import { CommentsOwner } from 'src/components/ResumeComments/types';

const MAX_COMMENT_LENGTH = 9999;

const COMMENT_URL = '/employer/applicant/json_comment';

const TrlKeys = {
    save: 'resume.comments.save',
    cancel: 'resume.comments.cancel',
    lengthError: 'resume.comments.lengthWarning',
};

declare global {
    interface FetcherPostApi {
        [COMMENT_URL]: {
            queryParams: void;
            body: {
                id?: number;
                accessType?: string | null;
                action: string;
                body?: string;
                applicantId: CommentsOwner;
                vacancyId?: string;
                topicId?: string;
                resumeHash?: string;
                verdict?: string;
            };
            response: ResumeComment;
        };
    }
}

export interface CommentsFormProps {
    action?: string;
    user: CommentsOwner;
    onSubmitSuccessful: (data: ResumeComment) => void;
    id?: number;
    accessType?: string | null;
    body?: string;
    onCancel: () => void;
    renderWrapper?: (renderers: {
        renderVisibilityField: () => ReactNode;
        renderBodyField: (props?: { rows?: number; 'data-qa'?: string }) => ReactNode;
        renderSubmit: (props?: { 'data-qa'?: string }) => ReactNode;
        renderCancel: () => ReactNode;
    }) => ReactNode;
    resumeHash?: string;
    vacancyId?: string;
    topicId?: string;
}

const CommentsForm: TranslatedComponent<CommentsFormProps> = ({
    trls,
    user,
    action = 'add',
    onSubmitSuccessful,
    onCancel,
    id,
    accessType = null,
    body = '',
    renderWrapper,
    resumeHash,
    topicId,
    vacancyId,
}) => {
    const [fetching, toggleFetching] = useToggleState(false);
    const textArea = useRef<HTMLTextAreaElement>(null);
    const userFeatures = useSelector((state) => state.userFeatures) || [];
    const { addNotification } = useNotification();
    const onSubmit = useCallback(
        (fields: { accessType?: string; action: string; body: string }) => {
            const _fields = { accessType, ...fields, applicantId: user, resumeHash, vacancyId, topicId };
            const enhancedFields = id ? { id, ..._fields } : _fields;
            toggleFetching();
            fetcher
                .postFormData(COMMENT_URL, enhancedFields)
                .then((response) => {
                    toggleFetching();
                    onSubmitSuccessful(response.data);
                })
                .catch((e) => {
                    toggleFetching();
                    defaultRequestErrorHandler(e, addNotification);
                });
        },
        [accessType, user, resumeHash, vacancyId, topicId, id, toggleFetching, onSubmitSuccessful, addNotification]
    );

    useEffect(() => {
        textArea.current?.focus?.();
    }, []);

    let handleSubmitForField: undefined | (() => Promise<unknown> | undefined);
    const renderVisibilityField = () => <CommentVisibilityField accessType={accessType} userFeatures={userFeatures} />;
    const renderBodyField = (props?: { rows?: number; 'data-qa'?: string }) => (
        <Fragment>
            <Field
                initialValue={action}
                name="action"
                type="hidden"
                render={({ input }) => <input type="hidden" {...input} />}
            />
            <Field
                validate={(value) => {
                    if (value && value.length >= MAX_COMMENT_LENGTH) {
                        return 'maxLength';
                    }
                    if (!value || !value.trim()) {
                        return 'empty';
                    }
                    return null;
                }}
                name="body"
                type="textarea"
                initialValue={body}
                render={({ input, meta }) => (
                    <UncontrolledTextArea
                        layout="fill-horizontal"
                        onValidate={() => undefined}
                        data-qa="comment-form__textarea"
                        ref={textArea}
                        invalid={meta.touched && !!meta.error}
                        maxLength={MAX_COMMENT_LENGTH}
                        onKeyPress={(e) => {
                            if ((e.key === 'Enter' || e.key === '\n') && e.ctrlKey) {
                                e.preventDefault();
                                void handleSubmitForField?.();
                            }
                        }}
                        errorMessage={
                            input.value
                                ? format(trls[TrlKeys.lengthError], {
                                      '{0}': MAX_COMMENT_LENGTH,
                                  })
                                : undefined
                        }
                        {...props}
                        {...input}
                    />
                )}
            />
        </Fragment>
    );
    const renderSubmit = (props?: { 'data-qa'?: string }) => (
        <Button
            type="submit"
            data-qa="comment-form__add"
            disabled={fetching}
            mode="primary"
            size="small"
            style="accent"
            {...props}
        >
            {trls[TrlKeys.save]}
        </Button>
    );

    const renderCancel = () => (
        <Button
            mode="secondary"
            data-qa="comment-form__cancel"
            onClick={onCancel}
            disabled={fetching}
            type="button"
            size="small"
        >
            {trls[TrlKeys.cancel]}
        </Button>
    );

    const render = renderWrapper || renderForm;

    return (
        <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
                handleSubmitForField = handleSubmit;
                return (
                    <Form
                        action={`/employer/applicant/json_comment?applicantId=${user}`}
                        method="POST"
                        onSubmit={handleSubmit}
                    >
                        {render({ renderVisibilityField, renderBodyField, renderSubmit, renderCancel })}
                    </Form>
                );
            }}
        />
    );
};

export default translation(CommentsForm);
