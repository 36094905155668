import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    nameIsEmpty: 'employer.resumeFolders.error.NAME_IS_EMPTY',
    nameIsTooLong: 'employer.resumeFolders.error.NAME_IS_TOO_LONG',
};

const NameIsEmpty: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.nameIsEmpty]}</>;

export const nameIsEmptyError = {
    Element: translation(NameIsEmpty),
    kind: 'error',
    autoClose: true,
};

const NameIsTooLong: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.nameIsTooLong]}</>;

export const nameIsTooLongError = {
    Element: translation(NameIsTooLong),
    kind: 'error',
    autoClose: true,
};
