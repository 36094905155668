import { useEffect, useState } from 'react';

import { HiringManagerInfo } from 'src/components/HiringManager/types';
import { ManagersList } from 'src/models/employerManagersPage';
import fetcher from 'src/utils/fetcher';

const GET_HIRING_MANAGER_LIST_URL = '/shards/employer/hiring_managers/list';

declare global {
    interface FetcherGetApi {
        [GET_HIRING_MANAGER_LIST_URL]: {
            queryParams: { vacancyId?: string };
            response: {
                hiringManagers: HiringManagerInfo[];
                managers: ManagersList[];
            };
        };
    }
}

type UseFetchHiringManagers = (vacancyId?: string) => {
    isLoading: boolean;
    hiringManagers: HiringManagerInfo[];
    activeManagers: ManagersList[];
};

export const useFetchHiringManagers: UseFetchHiringManagers = (vacancyId?: string) => {
    const [hiringManagers, setHiringMangers] = useState<HiringManagerInfo[]>([]);
    const [activeManagers, setActiveManagers] = useState<ManagersList[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await fetcher.get(GET_HIRING_MANAGER_LIST_URL, { params: { vacancyId } });
                setHiringMangers(response.hiringManagers);
                setActiveManagers(response.managers);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        };

        void fetchData();
    }, [vacancyId]);

    return {
        isLoading,
        hiringManagers,
        activeManagers,
    };
};
