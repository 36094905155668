import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    DiscardCandidates: {
        one: 'employer.discarded-notifier.rejection-confirmation.one',
        many: 'employer.discarded-notifier.rejection-confirmation.many',
    },
};

interface DiscardCandidatesProps {
    count: number;
}

const DiscardCandidates: TranslatedComponent<DiscardCandidatesProps> = ({ trls, count }) => {
    return (
        <Conversion
            many={trls[TrlKeys.DiscardCandidates.many]}
            some={trls[TrlKeys.DiscardCandidates.many]}
            one={trls[TrlKeys.DiscardCandidates.one]}
            value={count}
        />
    );
};

export default {
    Element: translation(DiscardCandidates),
    kind: 'ok',
    autoClose: true,
    autoCloseDelay: 3000,
};
