import { FC } from 'react';

import { VSpacing, HSpacing } from '@hh.ru/magritte-ui';

import { CommentFormEditProps } from 'src/components/ResumeComments/Item';

import styles from './index.less';

const CommentForm: FC<CommentFormEditProps> = ({
    renderVisibilityField,
    renderBodyField,
    renderSubmit,
    renderCancel,
}) => (
    <div className={styles.edit}>
        {renderBodyField({ 'data-qa': 'comment__edit-area', rows: 4 })}
        <VSpacing default={8} />
        {renderVisibilityField()}
        {renderSubmit({ 'data-qa': 'comment__save-button' })}
        <HSpacing default={12} />
        {renderCancel()}
    </div>
);

export default CommentForm;
