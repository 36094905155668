import { FC } from 'react';
import { Field } from 'react-final-form';

import { Switch } from '@hh.ru/magritte-ui';

const FieldWrappedSwitch: FC<{ name: string; onChange?: (value: boolean) => void }> = ({ name, onChange }) => (
    <Field<boolean> type="checkbox" name={name} defaultValue={false}>
        {({ input }) => (
            <Switch
                onClick={() => {
                    const newValue = !input.checked;
                    onChange?.(newValue);
                    input.onChange(newValue);
                }}
                checked={input.checked}
            />
        )}
    </Field>
);

export default FieldWrappedSwitch;
