import { useCallback, useState, useRef, ChangeEvent, MutableRefObject, ChangeEventHandler } from 'react';
import { useDispatch } from 'react-redux';

import { InputChangeHandlerProps } from 'bloko/blocks/inputText';

import { useNotification } from 'src/components/Notifications/Provider';
import { AppDispatch } from 'src/models/appDispatch';
import { FoldersGroupType, FoldersInvariants } from 'src/models/resumeFolders';

import createFolder from 'src/components/ResumeFolders/createFolder';

export enum SelectType {
    One = 'ONE',
    Many = 'MANY',
}

type InputChangeHandlerPropsCustom = (value: string, props?: InputChangeHandlerProps) => void;

type Props = {
    folders: FoldersInvariants;
    onSave: (arg: {
        foldersInvariants: FoldersInvariants;
        dispatch: AppDispatch;
        checkedFolders?: string[];
    }) => Promise<void>;
    selectedIdsFolder: string[];
    selectType?: SelectType;
};

type ReturnType = {
    onSubmit: () => void;
    onChangeAddFolderName: InputChangeHandlerPropsCustom;
    onChangeFolders: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeOneFolder: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeAddFolder: (event: ChangeEvent<HTMLInputElement>) => void;
    getSortedFolders: (folders: FoldersGroupType) => number[];
    checkedFolders: MutableRefObject<string[]>;
    isLoading: boolean;
    isCheckedAddFolder: boolean;
};

const useResumeFolder = ({
    folders,
    selectedIdsFolder = [],
    onSave,
    selectType = SelectType.Many,
}: Props): ReturnType => {
    const { addNotification } = useNotification();
    const [isLoading, setLoad] = useState(false);
    const [isCheckedAddFolder, setAddFolder] = useState(false);
    const checkedFolders = useRef(selectedIdsFolder);
    const newFolderName = useRef('');
    const dispatch: AppDispatch = useDispatch();

    const onChangeFolders = useCallback(({ target: { value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
        if (checked) {
            checkedFolders.current.push(value);
        } else {
            checkedFolders.current.splice(checkedFolders.current.indexOf(value), 1);
        }
    }, []);

    const onChangeOneFolder = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        checkedFolders.current = [value];
        setAddFolder(false);
    }, []);

    const onChangeAddFolder: ChangeEventHandler<HTMLInputElement> = useCallback(
        ({ target: { checked } }) => {
            setAddFolder(checked);
            if (selectType === SelectType.One) {
                checkedFolders.current = [];
            }
        },
        [selectType]
    );

    const onChangeAddFolderName: InputChangeHandlerPropsCustom = useCallback(
        (value) => {
            newFolderName.current = value;

            if (value.length > 0 && !isCheckedAddFolder) {
                setAddFolder(true);
            } else if (value.length === 0) {
                setAddFolder(false);
            }
        },
        [isCheckedAddFolder]
    );

    const putFolders = useCallback(
        (foldersInvariants: FoldersInvariants) => {
            onSave({
                foldersInvariants,
                dispatch,
                checkedFolders: checkedFolders.current,
            }).finally(() => setLoad(false));
        },
        [dispatch, onSave]
    );

    const onSubmit = useCallback(() => {
        setLoad(true);

        if (isCheckedAddFolder) {
            dispatch(createFolder({ newFolderName: newFolderName.current }, addNotification))
                .then(({ id, updatedFolders }) => {
                    checkedFolders.current.push(id);
                    putFolders(updatedFolders);
                })
                .catch(() => {
                    setLoad(false);
                });
        } else {
            putFolders(folders);
        }
    }, [addNotification, dispatch, folders, isCheckedAddFolder, putFolders]);

    const getSortedFolders = useCallback((folders: FoldersGroupType = []) => {
        return Object.keys(folders).sort((a, b) =>
            (folders[a]?.name || '').toLowerCase() > (folders[b]?.name || '').toLowerCase() ? 1 : -1
        );
    }, []);

    return {
        onSubmit,
        onChangeAddFolderName,
        onChangeFolders,
        onChangeOneFolder,
        onChangeAddFolder,
        getSortedFolders,
        checkedFolders,
        isLoading,
        isCheckedAddFolder,
    };
};

export default useResumeFolder;
