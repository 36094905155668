import { Fragment } from 'react';

import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface DateIntervalProps {
    /** Количество лет */
    years?: number;
    /** Количество месяцев */
    months?: number;
    /** Количество дней */
    days?: number;
}

const TrlKeys = {
    yearsOne: 'interval.years.0',
    yearsSome: 'interval.years.1',
    yearsMany: 'interval.years.2',
    yearsZero: 'interval.years.2',
    monthsOne: 'interval.months.0',
    monthsSome: 'interval.months.1',
    monthsMany: 'interval.months.2',
    monthsZero: 'interval.months.2',
    daysOne: 'lang.day.1',
    daysSome: 'lang.day.2',
    daysMany: 'lang.day.5',
    daysZero: 'lang.day.5',
};

const DateInterval: TranslatedComponent<DateIntervalProps> = ({ years = 0, months = 0, days = 0, trls }) => {
    return (
        <Fragment>
            {years > 0 && (
                <ConversionNumber
                    many={trls[TrlKeys.yearsMany]}
                    some={trls[TrlKeys.yearsSome]}
                    one={trls[TrlKeys.yearsOne]}
                    zero={trls[TrlKeys.yearsZero]}
                    value={years}
                />
            )}
            {years > 0 && months > 0 && ' '}
            {months > 0 && (
                <ConversionNumber
                    many={trls[TrlKeys.monthsMany]}
                    some={trls[TrlKeys.monthsSome]}
                    one={trls[TrlKeys.monthsOne]}
                    zero={trls[TrlKeys.monthsZero]}
                    value={months}
                />
            )}
            {(years > 0 || months > 0) && days > 0 && ' '}
            {days > 0 && (
                <ConversionNumber
                    many={trls[TrlKeys.daysMany]}
                    some={trls[TrlKeys.daysSome]}
                    one={trls[TrlKeys.daysOne]}
                    zero={trls[TrlKeys.daysZero]}
                    value={days}
                />
            )}
        </Fragment>
    );
};

export default translation(DateInterval);
