import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    1: 'month.1',
    2: 'month.2',
    3: 'month.3',
    4: 'month.4',
    5: 'month.5',
    6: 'month.6',
    7: 'month.7',
    8: 'month.8',
    9: 'month.9',
    10: 'month.10',
    11: 'month.11',
    12: 'month.12',
};

const isCorrectMonth = (month: number): month is keyof typeof TrlKeys => month in TrlKeys;

const MonthName: TranslatedComponent<{ month: number }> = ({ month, trls }) => (
    <>{isCorrectMonth(month) ? trls[TrlKeys[month]] : null}</>
);

export default translation(MonthName);
