export enum VacancyPermission {
    ViewContacts = 'VIEW_CONTACTS',
    ViewDesirableCompensation = 'VIEW_DESIRABLE_COMPENSATION',
}

export interface HiringManagerVacancyPermission {
    employerManagerId: number;
    permissions: VacancyPermission[];
    vacancyId: number;
}
