import { ChangeEventHandler } from 'react';
import add from 'date-fns/add';

import formatDate from 'Modules/formatDate';

export const ALLOWED_HOURS_GAP = 3;
export const HOURS_MINUTES_REGEXP = /(?:[01]\d|2[0-3]):[0-5]\d/;
const TIME_INPUT_VALUABLE_LENGTH = 5;

export const timeInputSize = new Date(2014, 1, 1, 15).toLocaleTimeString().indexOf('15') > -1 ? 11 : 15;

export const isValidTime = (time: string): boolean => HOURS_MINUTES_REGEXP.test(time);

export const getNextAllowedTime = (): Date => add(new Date(), { hours: ALLOWED_HOURS_GAP });

export const getClosestPossibleDate = (): [Date, string] => {
    const currentDate = getNextAllowedTime();
    return [currentDate, formatDate(currentDate, 'HH:mm')];
};

export const getClosesPossibleDateFormated = (): [string, string] => {
    const currentDate = getNextAllowedTime();
    return [formatDate(currentDate, 'yyyy-MM-dd'), formatDate(currentDate, 'HH:mm')];
};

export const getTimeChangeHandler = (timeInput: HTMLInputElement): ChangeEventHandler<HTMLInputElement> => {
    let previousTimeValue = timeInput.value || getClosestPossibleDate()[1];
    return ({ target }) => {
        const { selectionStart, selectionEnd } = target;
        const _start = selectionStart || 0;
        let value = target.value;

        if (selectionStart === selectionEnd) {
            value = value.slice(0, _start) + value.slice(_start + 1);
        }

        value = value.slice(0, TIME_INPUT_VALUABLE_LENGTH);

        if (isValidTime(value)) {
            target.value = value;
            previousTimeValue = value;
        } else {
            target.value = previousTimeValue;
        }

        const nextSelection = selectionStart === 2 ? selectionStart + 1 : selectionStart;
        target.setSelectionRange(nextSelection, nextSelection);
    };
};
