import { FC, useRef } from 'react';

import { useBreakpoint, useCollapsible } from '@hh.ru/magritte-ui';

import VacancyPermissions from 'src/components/HiringManager/VacancyPermissions';
import { getIsExpandedVacancyPermissions } from 'src/components/HiringManager/utils/getIsExpandedVacancyPermissions';
import { VSpacing } from 'src/components/MagritteRedesignComponents/Spacing';
import { ManagersList } from 'src/models/employerManagersPage';

interface VacancyShareSettingsProps {
    selectedManagers: ManagersList[];
    isVisible?: boolean;
}

const VacancyShareSettings: FC<VacancyShareSettingsProps> = ({ selectedManagers, isVisible = true }) => {
    const ref = useRef<HTMLDivElement>(null);
    const { isMobile } = useBreakpoint();
    const isExpanded = isVisible && getIsExpandedVacancyPermissions(selectedManagers);
    const { collapsibleClasses } = useCollapsible(ref, isExpanded);

    if (isMobile) {
        return isExpanded ? (
            <>
                <VSpacing default={16} />
                <VacancyPermissions />
            </>
        ) : null;
    }

    return (
        <div ref={ref} className={collapsibleClasses}>
            <VSpacing default={16} />
            <VacancyPermissions />
        </div>
    );
};

export default VacancyShareSettings;
