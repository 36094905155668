import { DefaultRootState } from 'react-redux';

import { VacancyPermission } from 'src/models/vacancy/permissions.types';

export const selectorCanCommunicateWithApplicant = (state: DefaultRootState): boolean => {
    const isHiringManagerExperiment = state.isHiringManagerExperiment;
    const vacancyPermission = state.hiringManagerVacancyPermission;

    if (!isHiringManagerExperiment || !vacancyPermission) {
        return true;
    }

    return vacancyPermission.permissions.includes(VacancyPermission.ViewContacts);
};
