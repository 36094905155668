import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface VideoInterviewSearchModalEnableProps {
    fullName: string;
}

const TrlKeys = {
    candidate: 'resume.online.interview.candidate',
    success: 'resume.online.interview.invite.success',
};

const VideoInterviewSearchModalEnable: TranslatedComponent<VideoInterviewSearchModalEnableProps> = ({
    fullName,
    trls,
}) => <>{`${fullName || trls[TrlKeys.candidate]} ${trls[TrlKeys.success]}`}</>;

export default {
    Element: translation(VideoInterviewSearchModalEnable),
    kind: 'ok',
    autoClose: true,
    autoCloseDelay: 3000,
};
