import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import pfpBuyContactsErrorElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_buy_contacts_error_element_shown';
import pfpTopupButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_topup_button_click';
import { Snackbar } from '@hh.ru/magritte-ui';
import { MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import routePaths from 'src/app/routePaths';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { BoughtTopicsError } from 'src/models/boughtTopics';

interface ErrorProps {
    error: BoughtTopicsError;
    resumeId: string;
    topicId?: string;
    vacancyId?: string;
    onClose: () => void;
}

const TrlKeys: Record<ErrorProps['error'] | 'balanceNotEnoughAction', string> = {
    RESUME_NOT_ACCESSIBLE: 'pfp.errors.resume_not_accessible',
    PERMISSION_MISSING: 'pfp.errors.permission_missing',
    CONTACTS_ALREADY_BOUGHT: 'pfp.errors.contacts_already_bought',
    CONTACTS_ALREADY_VISIBLE: 'pfp.errors.contacts_already_visible',
    BALANCE_NOT_ENOUGH: 'pfp.errors.balance_not_enough',
    UNHANDLED_ERROR: 'pfp.errors.unhandled_error',
    balanceNotEnoughAction: 'pfp.errors.balance_not_enough.actionLabel',
};

const Error: TranslatedComponent<ErrorProps> = ({ trls, error, onClose, resumeId, topicId, vacancyId }) => {
    const dispatch = useDispatch();
    const isBalanceNotEnough = error === 'BALANCE_NOT_ENOUGH';
    const balanceNotEnoughAction = useCallback(() => {
        pfpTopupButtonClick({ resumeId, topicId, vacancyId, hhtmFromLabel: 'pfp_balance_warning' });
        dispatch(push(`${routePaths.pricePayForPerformance}?hhtmFromLabel=snackbar`));
    }, [dispatch, resumeId, topicId, vacancyId]);
    const action = isBalanceNotEnough ? balanceNotEnoughAction : undefined;
    const actionLabel = isBalanceNotEnough ? trls[TrlKeys.balanceNotEnoughAction] : undefined;

    return (
        <ElementShownAnchor
            fn={pfpBuyContactsErrorElementShown}
            resumeId={resumeId}
            topicId={topicId}
            vacancyId={vacancyId}
            errorCode={error}
        >
            <Snackbar
                showClose
                addon={<MinusCircleFilledSize24 initial={'negative'} />}
                onClose={onClose}
                onAction={action}
                actionLabel={actionLabel}
            >
                {trls[TrlKeys[error]]}
            </Snackbar>
        </ElementShownAnchor>
    );
};

export default translation(Error);
