import { useState } from 'react';

import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import SingleCalendarPicker from 'bloko/blocks/calendar/SingleCalendarPicker';
import CustomSelect, { CustomSelectOption, CustomSelectLayer } from 'bloko/blocks/customSelect';
import { DownLayer } from 'bloko/blocks/drop/Down';
import MenuClick from 'bloko/blocks/drop/Menu/Click';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import { H2 } from 'bloko/blocks/header';
import { ClockScaleSmallKindDefault, CalendarScaleSmall, IconColor } from 'bloko/blocks/icon';
import InputText, { InputType } from 'bloko/blocks/inputText';
import ModalFooter from 'bloko/blocks/modal/ModalFooter';
import Select, { Option } from 'bloko/blocks/select';
import Text from 'bloko/blocks/text';
import Textarea from 'bloko/blocks/textarea';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import Info from 'src/components/InviteToVideoCall/Info';
import {
    VideoCallDuration,
    VideoCallVacancyData,
    VideoCallTimeData,
    VideoCallDateData,
    VideoCallDurationData,
    VideoCallCommentData,
    VideoCallInputDateData,
} from 'src/components/InviteToVideoCall/types';

import styles from './index.less';

const TrlKeys = {
    cancel: 'Cancel',
    continue: 'resume.online.interview.invite.continue',
    title: 'resume.online.interview.invite.title',
    time: 'resume.online.interview.invite.time',
    date: 'resume.online.interview.invite.date',
    comment: 'resume.online.interview.invite.comment',
    duration: 'resume.online.interview.invite.duration',
    min: 'resume.online.interview.invite.min',
    step: 'resume.online.interview.invite.step.one',
    vacancy: 'resume.online.interview.invite.vacancy',
    selectVacancy: 'employer.invite.selectVacancy',
    loading: 'resume.online.interview.invite.vacancy.loading',
    hour: 'hour.1',
    hours: 'hour.2',
};

interface InviteFormProps {
    inputDate: VideoCallInputDateData;
    date: VideoCallDateData;
    comment: VideoCallCommentData;
    duration: VideoCallDurationData;
    vacancy: VideoCallVacancyData;
    time: VideoCallTimeData;
    nextStep: () => void;
    onClose: () => void;
}

const InviteForm: TranslatedComponent<InviteFormProps> = ({
    trls,
    inputDate,
    date,
    comment,
    duration,
    vacancy,
    time,
    nextStep,
    onClose,
}) => {
    const [host, setHost] = useState<HTMLDivElement | null>(null);

    let selectVacancy;
    if (vacancy.list.length === 1) {
        selectVacancy = (
            <Select defaultValue={vacancy.value?.toString()} disabled>
                <Option value={vacancy.list[0].vacancyId?.toString()}>{vacancy.list[0].name}</Option>
            </Select>
        );
    } else {
        selectVacancy = (
            <CustomSelect
                search={vacancy.list.length > 5}
                value={vacancy.value?.toString()}
                layer={CustomSelectLayer.AboveOverlayContent}
                onChange={vacancy.onChange}
                emptyPlaceholder={trls[TrlKeys.selectVacancy]}
                autoClose={false}
            >
                {vacancy.list.map((vacancy) => (
                    <CustomSelectOption value={vacancy.vacancyId} key={vacancy.vacancyId}>
                        {vacancy.name}
                    </CustomSelectOption>
                ))}
            </CustomSelect>
        );
    }

    return (
        <div>
            <H2>{trls[TrlKeys.title]}</H2>
            <VSpacing base={6} />
            <Text strong>{trls[TrlKeys.vacancy]}</Text>
            <VSpacing base={1} />
            {selectVacancy}
            <VSpacing base={6} />
            <div className={styles.row}>
                <div className={styles.column}>
                    <div>
                        <Text strong>{trls[TrlKeys.date]}</Text>
                        <VSpacing base={1} />
                        <div ref={(element) => element && setHost(element)}>
                            <MenuClick
                                host={host}
                                layer={DownLayer.AboveOverlayContent}
                                render={(close) => (
                                    <div className={styles.calendar}>
                                        <SingleCalendarPicker
                                            initialDate={date.currentAvailable}
                                            highlighted={date.value?.toString()}
                                            disableDaysBeforeDate={date.currentAvailable}
                                            onDateClick={(newDate) => {
                                                newDate && date.onChange(newDate);
                                                close?.();
                                            }}
                                        />
                                    </div>
                                )}
                            >
                                <InputText
                                    maxLength={6}
                                    type={InputType.Date}
                                    value={inputDate.value}
                                    onChange={inputDate.onChange}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                    icon={<CalendarScaleSmall initial={IconColor.Gray60} />}
                                />
                            </MenuClick>
                        </div>
                    </div>
                    <VSpacing base={4} />
                    <div className={styles.rowTime}>
                        <div className={styles.columnTime}>
                            <Text strong>{trls[TrlKeys.time]}</Text>
                            <VSpacing base={1} />
                            <InputText
                                type={InputType.Time}
                                value={time.value}
                                onChange={time.onChange}
                                icon={<ClockScaleSmallKindDefault initial={IconColor.Gray60} />}
                            />
                        </div>
                        <div className={styles.columnTime}>
                            <Text strong>{trls[TrlKeys.duration]}</Text>
                            <VSpacing base={1} />
                            <Select value={duration.value?.toString()} onChange={duration.onChange}>
                                <Option value={VideoCallDuration.QuaterAnHour}>15 {trls[TrlKeys.min]}</Option>
                                <Option value={VideoCallDuration.HalfAnHour}>30 {trls[TrlKeys.min]}</Option>
                                <Option value={VideoCallDuration.ThreeQuatersAnHour}>45 {trls[TrlKeys.min]}</Option>
                                <Option value={VideoCallDuration.Hour}>1 {trls[TrlKeys.hour]}</Option>
                                <Option value={VideoCallDuration.HourAndAHalf}>1:30</Option>
                                <Option value={VideoCallDuration.TwoHours}>2 {trls[TrlKeys.hours]}</Option>
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <Text strong>{trls[TrlKeys.comment]}</Text>
                    <VSpacing base={1} />
                    <Textarea value={comment.value} onChange={comment.onChange} rows={5} maxLength={1000} />
                </div>
            </div>
            <Info />
            <ModalFooter>
                <Button appearance={ButtonAppearance.Outlined} onClick={onClose} data-qa="invite-video-call-close">
                    {trls[TrlKeys.cancel]}
                </Button>
                <FormSpacer>
                    <Button
                        kind={ButtonKind.Primary}
                        onClick={nextStep}
                        disabled={!vacancy.value || !inputDate.value}
                        data-qa="invite-video-call-continue"
                    >
                        {trls[TrlKeys.continue]}
                    </Button>
                </FormSpacer>
            </ModalFooter>
        </div>
    );
};

export default translation(InviteForm);
