import { useCallback, useMemo, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Cell,
    CellText,
    Modal,
    NavigationBar,
    useBreakpoint,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import CreateHiringManagerButton from 'src/components/HiringManager/CreateHiringManagerButton';
import SubmitButton from 'src/components/HiringManager/SubmitButton';
import HiringManagerSuggest from 'src/components/HiringManager/Suggest';
import FieldWrappedSwitch from 'src/components/HiringManager/VacancyPermissions/VacancyPermission/FieldWrappedSwitch';
import VacancyShareSettings from 'src/components/HiringManager/VacancyShareModal/VacancyShareSettings';
import { useCopyClipboard } from 'src/components/HiringManager/api/useCopyClipboard';
import { useShareResume } from 'src/components/HiringManager/api/useShareResume';
import { useFetchHiringManagers } from 'src/components/HiringManager/hooks/useFetchHiringManagers';
import { ResumeShareFormData } from 'src/components/HiringManager/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ManagersList } from 'src/models/employerManagersPage';

const TrlKeys = {
    title: 'resume.share',
    subtitle: 'employer.vacancy.share.modal.titleDescription',
    configureVacancyPermissions: 'employer.resume.share.modal.configureVacancyPermissions',
    copy: 'employer.resume.share.modal.copy',
    requiredError: 'employer.hiringManager.suggest.requiredError',
};

interface ResumeShareModalProps {
    visible: boolean;
    setOff: () => void;
    resumeHash: string[];
    resumeName: string[];
    topicId?: string;
}

const FORM_ID = 'resume-share-modal';

const ResumeShareModal: TranslatedComponent<ResumeShareModalProps> = ({
    trls,
    visible,
    setOff,
    resumeHash,
    resumeName,
    topicId,
}) => {
    const { isMobile } = useBreakpoint();
    const required = useCallback(
        (value: string[]) => {
            if (Array.isArray(value)) {
                return value.length ? undefined : trls[TrlKeys.requiredError];
            }

            return value ? undefined : trls[TrlKeys.requiredError];
        },
        [trls]
    );
    const [selectedManagers, setSelectedManagers] = useState<ManagersList[]>([]);
    const [configureVacancyPermissions, setConfigureVacancyPermissions] = useState(false);
    const vacancyFull = useSelector((state) => state.vacancyFull);
    const onClose = useCallback(() => {
        setOff();
        setSelectedManagers([]);
    }, [setOff]);

    const { isLoading, hiringManagers, activeManagers } = useFetchHiringManagers(vacancyFull?.vacancyId.toString());
    const managersToShare = useMemo(
        () =>
            activeManagers.reduce((acc, activeManager) => {
                if (!hiringManagers.find((hm) => hm.employerManagerId === activeManager.id)) {
                    acc.push(activeManager);
                }
                return acc;
            }, [] as ManagersList[]),
        [activeManagers, hiringManagers]
    );

    const handleSubmit = useShareResume(
        selectedManagers,
        resumeHash,
        managersToShare.length ? vacancyFull : null,
        onClose
    );

    const isVacancyOwner =
        useSelector((state) => state.employerManager?.id) === vacancyFull?.employerManager?.['@managerId'].toString();
    const isMCPSGroup = useSelector((state) => state.permissions.includes('MCPSGroup'));

    const canShareVacancy = isVacancyOwner || isMCPSGroup;

    const content = (
        <FinalForm<ResumeShareFormData> onSubmit={handleSubmit}>
            {({ handleSubmit, values }) => (
                <Form onSubmit={handleSubmit} id={FORM_ID}>
                    <Field<string[]> name="hiringManager" validate={required}>
                        {({ input, meta }) => (
                            <HiringManagerSuggest
                                isLoading={isLoading}
                                hiringManagers={hiringManagers}
                                activeManagers={activeManagers}
                                setSelectedManagers={setSelectedManagers}
                                isInvalid={meta.touched && meta.invalid}
                                error={meta.error as string}
                                description={isMobile && trls[TrlKeys.subtitle]}
                                filterHiringManagers={values.configureVacancyPermissions}
                                {...input}
                            />
                        )}
                    </Field>
                    {vacancyFull?.vacancyId && canShareVacancy && (
                        <>
                            <VSpacing default={16} />
                            <Cell
                                right={
                                    <FieldWrappedSwitch
                                        name="configureVacancyPermissions"
                                        onChange={setConfigureVacancyPermissions}
                                    />
                                }
                            >
                                <CellText type="title">{trls[TrlKeys.configureVacancyPermissions]}</CellText>
                            </Cell>
                            {!!managersToShare.length && (
                                <VacancyShareSettings
                                    selectedManagers={selectedManagers}
                                    isVisible={values.configureVacancyPermissions}
                                />
                            )}
                        </>
                    )}
                </Form>
            )}
        </FinalForm>
    );
    const leftButton =
        resumeHash.length === 1 ? (
            <CreateHiringManagerButton
                shareVacancy={configureVacancyPermissions}
                vacancyId={vacancyFull?.vacancyId.toString()}
                vacancyName={vacancyFull?.name}
                resumeHash={resumeHash[0]}
                resumeName={resumeName[0]}
                onClick={setOff}
                key="createHiringManager"
            />
        ) : null;
    const clipboardCopy = useCopyClipboard(resumeHash[0], vacancyFull, topicId);
    const rightButtons = [
        resumeHash.length === 1 && (
            <Button key="copyToClipboard" mode="tertiary" style="accent" onClick={clipboardCopy}>
                {trls[TrlKeys.copy]}
            </Button>
        ),
        <SubmitButton kind="shareResume" key="shareResume" formId={FORM_ID} />,
    ].filter(Boolean);
    return (
        <>
            <Modal
                visible={visible}
                title={trls[TrlKeys.title]}
                titleSize="medium"
                titleDescription={trls[TrlKeys.subtitle]}
                titleDescriptionStyle="secondary"
                actions={[<Action key="close" onClick={setOff} icon={CrossOutlinedSize24} mode="secondary" />]}
                leftButtons={[leftButton]}
                rightButtons={[rightButtons]}
            >
                {content}
            </Modal>
            <BottomSheet
                visible={visible}
                onClose={setOff}
                header={<NavigationBar title={trls[TrlKeys.title]} />}
                footer={
                    <BottomSheetFooter>
                        {leftButton}
                        {rightButtons}
                    </BottomSheetFooter>
                }
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default translation(ResumeShareModal);
