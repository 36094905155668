import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    yearsOne: 'age.years.0',
    yearsSome: 'age.years.1',
    yearsMany: 'age.years.2',
    yearsZero: 'age.years.2',
};

const Age: TranslatedComponent<{ value: number }> = ({ value, trls }) => {
    return (
        <ConversionNumber
            many={trls[TrlKeys.yearsMany]}
            some={trls[TrlKeys.yearsSome]}
            one={trls[TrlKeys.yearsOne]}
            zero={trls[TrlKeys.yearsZero]}
            value={value}
        />
    );
};

export default translation(Age);
