import { ComplaintReason } from 'src/models/complaintReasonTypes';

export enum FieldName {
    ComplaintReason = 'complaintReason',
    ComplaintReasonComment = 'reasonComment',
    AddToBlackList = 'addToBlackList',
}

export interface FormValues {
    [FieldName.ComplaintReason]?: ComplaintReason;
    [FieldName.ComplaintReasonComment]?: string;
    [FieldName.AddToBlackList]: boolean;
}

export enum ValidationError {
    Required = 'required',
}

export type ValidationErrors = Partial<Record<FieldName, ValidationError>>;
