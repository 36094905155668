import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Banner, Button, Card, Select, SelectOption, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { HiringManagerInfo } from 'src/components/HiringManager/types';
import { getIsManagerMCP } from 'src/components/HiringManager/utils/getIsManagerMCP';
import SelectFixedHeight from 'src/components/VacancyFunnel/SelectFixedHeight';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ManagersList } from 'src/models/employerManagersPage';

const TrlKeys = {
    selectPlaceholder: 'employer.vacancy.share.modal.selectPlaceholder',
    applyButton: 'employer.hiringManager.suggest.applyButton',
    mcp: 'employer.hiringManager.mcp',
    nonMcp: 'employer.hiringManager.nonMcp',
    noOptions: 'employer.hiringManager.suggest.noOptions',
};

interface HiringManagerSuggestProps {
    name: string;
    hiringManagers: HiringManagerInfo[];
    activeManagers: ManagersList[];
    isLoading: boolean;
    onChange: (value: string[]) => void;
    value: string[];
    setSelectedManagers: (managers: ManagersList[]) => void;
    error?: string;
    isInvalid?: boolean;
    description?: ReactNode;
    filterHiringManagers?: boolean;
}

const HiringManagerSuggest: TranslatedComponent<HiringManagerSuggestProps> = ({
    trls,
    onChange,
    name,
    value,
    setSelectedManagers,
    error,
    isInvalid,
    description,
    filterHiringManagers = false,
    activeManagers,
    hiringManagers,
    isLoading,
}) => {
    const currentManagerId = useSelector((state) => state.employerManager?.id);
    const [selectedManagers, selectManagers] = useState<string[]>([]);

    const options: SelectOption[] = useMemo(() => {
        const [mcp, nonMcp] = activeManagers.reduce(
            (acc, manager) => {
                const isManagerExcluded = filterHiringManagers
                    ? hiringManagers.length && hiringManagers.find((hm) => hm.employerManagerId === manager.id)
                    : manager.id.toString() === currentManagerId;
                if (isManagerExcluded) {
                    return acc;
                }
                const idx = getIsManagerMCP(manager) ? 0 : 1;
                acc[idx].push({ label: manager.fullName, value: manager.id.toString() });
                return acc;
            },
            [[], []] as Array<SelectOption[]>
        );
        return ([] as SelectOption[])
            .concat(mcp.length ? [{ type: 'delimiter', value: 'mcp', label: trls[TrlKeys.mcp] }] : [])
            .concat(mcp)
            .concat(nonMcp.length ? [{ type: 'delimiter', value: 'nonMcp', label: trls[TrlKeys.nonMcp] }] : [])
            .concat(nonMcp);
    }, [activeManagers, trls, filterHiringManagers, hiringManagers, currentManagerId]);

    useEffect(() => {
        selectManagers(
            activeManagers
                .filter((manager) => value.includes(manager.id.toString()))
                .map((manager) => manager.id.toString())
        );
    }, [activeManagers, setSelectedManagers, value]);

    const onClose = useCallback(() => {
        const newVal = activeManagers.filter((manager) => selectedManagers.includes(manager.id.toString()));
        setSelectedManagers(newVal);
        onChange(newVal.map((manager) => manager.id.toString()));
    }, [activeManagers, onChange, selectedManagers, setSelectedManagers]);

    const onChangeHandler = useCallback((values: string[]) => {
        selectManagers(values);
    }, []);

    if (isLoading || !options.length) {
        return (
            <Card.Skeleton height={56} loading={isLoading} borderRadius={16} stretched>
                <Banner
                    stretched
                    showClose={false}
                    style="warning"
                    content={<Text typography="paragraph-2-regular">{trls[TrlKeys.noOptions]}</Text>}
                />
            </Card.Skeleton>
        );
    }

    return (
        <SelectFixedHeight>
            <Select
                multiple
                name={name}
                size="large"
                searchable
                disabled={isLoading}
                options={options}
                placeholder={trls[TrlKeys.selectPlaceholder]}
                applyChangesButton={
                    <Button mode="primary" style="accent">
                        {trls[TrlKeys.applyButton]}
                    </Button>
                }
                onChange={onChangeHandler}
                onDropClose={onClose}
                onBottomSheetClose={onClose}
                value={selectedManagers || []}
                invalid={isInvalid}
                errorMessage={error}
                description={description}
            />
        </SelectFixedHeight>
    );
};

export default translation(HiringManagerSuggest);
