import { Field } from 'react-final-form';

import { Cell, CellText, Radio } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ComplaintReason, ComplaintReasonTypes } from 'src/models/complaintReasonTypes';

import { FieldName, FormValues } from 'src/components/ResumeComplaint/ResumeComplaintForm/types';

const TrlKeys = {
    [ComplaintReason.AdInNegotiation]: 'employer.vacancyResponses.complaintReason.AD_IN_NEGOTIATION',
    [ComplaintReason.AdInResume]: 'employer.vacancyResponses.complaintReason.AD_IN_RESUME',
    [ComplaintReason.TooManyNegotiations]: 'employer.vacancyResponses.complaintReason.TOO_MANY_NEGOTIATIONS',
    [ComplaintReason.NoSenseNegotiation]: 'employer.vacancyResponses.complaintReason.NO_SENSE_NEGOTIATION',
    [ComplaintReason.Other]: 'employer.vacancyResponses.complaintReason.OTHER',
};

interface ComplaintReasonFieldProps {
    complaintReasonTypes: ComplaintReasonTypes;
}

const ComplaintReasonFields: TranslatedComponent<ComplaintReasonFieldProps> = ({ trls, complaintReasonTypes }) => (
    <div role="radiogroup">
        {complaintReasonTypes.map(({ reasonType }) => (
            <Field<FormValues[FieldName.ComplaintReason]>
                key={reasonType}
                value={reasonType}
                name={FieldName.ComplaintReason}
                type="radio"
                render={({ input }) => (
                    <Cell left={<Radio {...input} data-qa="resume-complaint-form__reason" />} vertPadding>
                        <CellText>{trls[TrlKeys[reasonType]]}</CellText>
                    </Cell>
                )}
            />
        ))}
    </div>
);

export default translation(ComplaintReasonFields);
