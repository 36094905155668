import { useEffect } from 'react';
import { Field, useField, useForm } from 'react-final-form';

import { TextArea } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ComplaintReason } from 'src/models/complaintReasonTypes';

import { FieldName, FormValues } from 'src/components/ResumeComplaint/ResumeComplaintForm/types';

const TrlKeys = {
    placeholder: 'employer.vacancyResponses.complaintReason.reasonText',
};

const ComplaintReasonCommentField: TranslatedComponent = ({ trls }) => {
    const form = useForm();
    const complaintReasonFieldValue = useField<FormValues[FieldName.ComplaintReason]>(FieldName.ComplaintReason, {
        subscription: { value: true },
    }).input.value;
    const shouldNotShowField = complaintReasonFieldValue !== ComplaintReason.Other;

    useEffect(() => {
        if (shouldNotShowField) {
            form.mutators.removeValue(FieldName.ComplaintReasonComment);
        }
    }, [shouldNotShowField, form.mutators]);

    if (shouldNotShowField) {
        return null;
    }

    return (
        <Field<string>
            name={FieldName.ComplaintReasonComment}
            initialValue=""
            render={({ input }) => (
                <TextArea
                    {...input}
                    data-qa="resume-complaint-form__reason-comment"
                    placeholder={trls[TrlKeys.placeholder]}
                    layout="fill-horizontal"
                />
            )}
        />
    );
};

export default translation(ComplaintReasonCommentField);
