import { cloneElement, ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { SelectProps } from '@hh.ru/magritte-ui';

import { getMaxHeight } from 'src/utils/select/getMaxHeight';

type Props<ShowClearButtonType extends boolean, MultipleType extends boolean, T = unknown> = {
    children: ReactElement<Partial<SelectProps<ShowClearButtonType, MultipleType, T>>>;
    onFocus?: () => void;
};

const SelectFixedHeight = <ShowClearButtonType extends boolean, MultipleType extends boolean, T = unknown>({
    children,
    onFocus,
}: Props<ShowClearButtonType, MultipleType, T>): ReactElement => {
    const [maxHeight, setMaxHeight] = useState(460);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setMaxHeight(getMaxHeight(selectRef));
    }, []);

    const onFocusCb = useCallback(() => {
        setMaxHeight(getMaxHeight(selectRef));
        onFocus?.();
    }, [onFocus]);

    return (
        <div ref={selectRef}>
            {cloneElement(children, {
                ...children.props,
                onFocus: onFocusCb,
                maxHeight,
            })}
        </div>
    );
};

export default SelectFixedHeight;
