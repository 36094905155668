import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    errorLoading: 'resume.employer.history.error.loading',
};

const LoadError: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.errorLoading]}</>;

export default {
    Element: translation(LoadError),
    kind: 'error',
};
