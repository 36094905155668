import { useState } from 'react';

import Link, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './index.less';

const TrlKeys = {
    expand: 'resume.online.interview.modal.expand',
    contactOne: 'resume.online.interview.modal.contact.1',
    contactTwo: 'resume.online.interview.modal.contact.2',
    contactThree: 'resume.online.interview.modal.contact.3',
};

const Info: TranslatedComponent = ({ trls }) => {
    const [show, setShow] = useState(false);
    return (
        <div className={styles.disclaimer}>
            <Link appearance={LinkAppearance.Pseudo} onClick={() => setShow(!show)}>
                {trls[TrlKeys.expand]}
            </Link>
            {show && (
                <>
                    <VSpacing base={3} />
                    <ol className={styles.disclaimerList}>
                        <li>{trls[TrlKeys.contactOne]}</li>
                        <li>{trls[TrlKeys.contactTwo]}</li>
                        <li>{trls[TrlKeys.contactThree]}</li>
                    </ol>
                </>
            )}
        </div>
    );
};

export default translation(Info);
