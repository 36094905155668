import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    text: 'vacancyResponse.noCallAnswer.notification',
};

const ApplicantNoCallAnswer: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.text]}</>;

export default {
    Element: translation(ApplicantNoCallAnswer),
    kind: 'ok',
    autoClose: true,
};
